
import { Component, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
import { NodeInfo } from '@/types/state'
import { UserRole } from '@/enums'

@Component({
  data() {
    return {
      mini: true
    }
  }
})
export default class Navigation extends Vue {

  EMRA_SUBGROUP_ICON = "mdi-home-circle-outline"
  emraSubgroupExpanded = false

  get numberOfPreparationNodes(): number {
    return this.$store.getters['default/NodeInfos'].filter((n: NodeInfo) =>
      n.readyForPlacement || n.prepared || n.toBePrepared)
    .length
  }
  
  get detailNode(): NodeInfo { return this.$store.getters['emraPageInfo/DetailNode'] }

  role = store.getters['identity/role']
  get items(): any {
      return this.role == UserRole.Moderator ? [
        { title: 'Home', icon: 'mdi-home', path: '/mod'},
        { title: 'Preparatie', icon: 'mdi-playlist-check', chip: this.numberOfPreparationNodes, path: '/mod/emras-prepared'},
        { title: 'Buiten gebruik', icon: 'mdi-home-off-outline', path: '/mod/emras-out-of-use'},
        { title: 'EMRA-box toevoegen', icon: 'mdi-home-plus-outline', path: '/mod/add-emra'},
        { title: !this.detailNode ? 'EMRA-box' : this.detailNode.alias, icon: this.EMRA_SUBGROUP_ICON, expanded: this.emraSubgroupExpanded, children: [
          { title: 'Informatie en meters', icon: 'mdi-information-box-outline', path: '/mod/emra'},
          { title: 'Meetdata', icon: 'mdi-calendar-filter', path: '/mod/data' },
          { title: 'Berekeningen', icon: 'mdi-chart-timeline-variant', path: '/mod/mod-node'},
        ]},
        { title: 'Gebruikers', icon: 'mdi-account-details-outline', path: '/mod/users'}
      ]

      : [
        { title: 'Home', icon: 'mdi-home', path: '/default' },
        { title: 'Data', icon: 'mdi-calendar-filter', path: '/default/data' }
      ]
  }

}
